import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, visuallyHidden } from '../styles';
import { Svg } from './ui';
import starIcon from '../images/star-icon.svg';

const StyledStars = styled.div`
  white-space: nowrap;
`;

const StyledText = styled.p`
  ${visuallyHidden()};
`;

const StyledSvg = styled(Svg)`
  display: inline-block;
  margin-left: 2px;
  width: 18px;
  height: 18px;

  ${minBreakpointQuery.large`
    margin-left: 3px;
    width: 20px;
    height: 20px;
  `}

  &:first-child {
    margin-left: 0;
  }
`;

const Stars = ({ rating, ...props }) => {
  let stars = [];

  for (var i = 0; i < rating; i++) {
    stars.push(<StyledSvg image={starIcon} />);
  }

  return (
    <StyledStars {...props}>
      <StyledText>{rating} Stars</StyledText>
      {stars}
    </StyledStars>
  );
};

export default Stars;
